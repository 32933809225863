import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChinookLogo from './../../Assets/Images/chinookLogo.jpg';
import HomeIcon from '@mui/icons-material/Home';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PaidIcon from '@mui/icons-material/Paid';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 240;

function ServiceAreasDropdown() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button sx={{ color: '#fff', mx: 1 }} onClick={handleClick}>
                Our Service Areas
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'service-areas-button' }}
            >
                <MenuItem onClick={handleClose} component={Link} to="/athol">Athol</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/blanchard">Blanchard</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/coeur-d-alene">Coeur d'Alene</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/kellogg">Kellogg</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/plummer">Plummer</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/post-falls">Post Falls</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/priest-river">Priest River</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/rathdrum">Rathdrum</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/sandpoint">Sandpoint</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/spirit-lake">Spirit Lake</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} component={Link} to="/deer-park">Deer Park</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/newport">Newport</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/spokane">Spokane</MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} component={Link} to="/benewah-county">Benewah County</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/bonner-county">Bonner County</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/kootenai-county">Kootenai County</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/pend-oreille-county">Pend Oreille County</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/spokane-county">Spokane County</MenuItem>
            </Menu>
        </>
    );
}

function NavBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:850px)');

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <nav onClick={handleDrawerToggle} style={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
            <img
                src={ChinookLogo}
                alt='Chinook Water Testing logo'
                style={{ width: '150px', marginTop: 20, marginBottom: 10, marginLeft: 45 }}
                loading="lazy"
            />
            <Divider />
            <List>
                <ListItem>
                    <Link to='/'>
                        <ListItemButton style={{ textAlign: 'center', padding: '0 20px', textTransform: 'none' }}>
                            <HomeIcon fontSize='medium' style={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Home</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to='/service-areas'>
                        <ListItemButton style={{ textAlign: 'center', padding: '0 20px' }}>
                            <BuildCircleIcon style={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Service Areas</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to='/pricing'>
                        <ListItemButton style={{ textAlign: 'center', padding: '0 20px' }}>
                            <PaidIcon style={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Pricing</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem>
                    <Link to='/contact'>
                        <ListItemButton style={{ textAlign: 'center', padding: '0 20px' }}>
                            <ContactMailIcon style={{ marginRight: '10px', color: '#114796' }} />
                            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>Contact</p>
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
        </nav>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav style={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: '#28528d', minHeight: 75, paddingTop: 12 }}>
                {isMobile ? (
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        {/* Hamburger */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '12%' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ ml: .5 }}
                            >
                                <MenuIcon sx={{ fontSize: '2rem' }} />
                            </IconButton>
                        </Box>

                        {/* Phone number centered */}
                        <Box sx={{ width: '66%', textAlign: 'center' }}>
                            <Typography variant="body1" sx={{ fontFamily: 'sans-serif', fontSize: '1em' }}>
                                <a href="tel:+12088189080" style={{ textDecoration: "none", color: "inherit" }}>
                                    +1 (208) 818-9080
                                </a>
                            </Typography>
                        </Box>

                        {/* Logo right aligned */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '22%', pr: .5 }}>
                            <Link to="/">
                                <img
                                    src={ChinookLogo}
                                    width="72"
                                    alt="Chinook Water Testing logo"
                                    style={{ border: '3px solid white', borderRadius: '50%' }}
                                    loading="lazy"
                                />
                            </Link>
                        </Box>
                    </Toolbar>
                ) : (
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                            <Link to='/'>
                                <img
                                    src={ChinookLogo}
                                    width="100"
                                    alt='Chinook Water Testing logo'
                                    style={{ margin: '12px 20px 15px 0px', border: '10px solid white', borderRadius: '50%' }}
                                    loading="lazy"
                                />
                            </Link>
                            <Typography variant="body1" style={{ fontFamily: "sans-serif", fontSize: "1.2em" }}>
                                <a href="tel:+12088189080" style={{ textDecoration: "none", color: "inherit" }}>
                                    +1 (208) 818-9080
                                </a>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Link to='/'>
                                <Button sx={{ color: '#fff', mx: 1 }}>Home</Button>
                            </Link>
                            <ServiceAreasDropdown />
                            <Link to='/pricing'>
                                <Button sx={{ color: '#fff', mx: 1 }}>Pricing</Button>
                            </Link>
                            <Link to='/contact'>
                                <Button sx={{ color: '#fff', mx: 1 }}>Contact</Button>
                            </Link>
                        </Box>
                    </Toolbar>
                )}
            </AppBar>

            <Box component="nav">
                {isMobile && (
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                )}
            </Box>
        </nav>
    );
}

export default NavBar;
