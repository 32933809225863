import React from "react";

export default function Loader() {
  return (
    <div style={{
      height: '100vh',
      backgroundColor: '#28528d',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2rem',
    }}>
      <img src="/fish-logo.webp" alt="Chinook Water Testing Logo" style={{ width: '25%' }} />
    </div>
  );
}
