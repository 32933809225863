import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Box, Button } from "@material-ui/core";
import useStyles from "./Pricing.Styles";

function Pricing() {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <Helmet>
            <title>Well Water Testing & System Inspection Pricing | North Idaho & Eastern WA</title>
                <meta
                    name="description"
                    content="Explore pricing options for water testing, flow rate testing (GPM), and system inspections in North Idaho & Eastern Washington. Call for fast pricing."
                />
                <meta
                    name="keywords"
                    content="well water test pricing, flow rate test cost, water inspection quote, real estate water testing, North Idaho, Eastern Washington"
                />
                <meta
                    property="og:title"
                    content="Well Water Testing & System Inspection Pricing | North Idaho & Eastern WA"
                />
                <meta
                    property="og:description"
                    content="Explore pricing options for water testing, flow rate testing (GPM), and system inspections in North Idaho & Eastern Washington. Call for fast pricing."
                />
                <meta name="author" content="Chinook Water Testing" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Chinook Water Testing" />
                <meta property="og:url" content="https://chinookwater.com/pricing" />
                <meta property="og:image" content="https://chinookwater.com/chinookLogo.jpg" />
                <meta property="og:image:alt" content="Chinook Water Testing company logo" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://chinookwater.com/pricing" />
            </Helmet>

            <header className="sr-only">
                Pricing for Certified Well Water Testing, Flow Rate Testing & System Inspections | North Idaho & Eastern Washington
            </header>

            <Box className={classes.titleBox}>
                <Typography variant="h1" className={classes.title}>
                    Well Water Testing Pricing in North Idaho & Eastern Washington
                </Typography>
            </Box>

            <Box className={classes.contentBox}>
                <Typography variant="body1" className={classes.paragraph}>
                    At Chinook Water Testing, we understand that every property and water system is unique. As a fully licensed and insured company, we offer <strong>custom pricing</strong> tailored to the location, testing needs, and inspection requirements of your property.
                </Typography>

                <Typography variant="h2" className={classes.subheading}>
                    What Affects Pricing?
                </Typography>
                <ul className={classes.bulletList}>
                    <li>Type of water testing required (bacteria, metals, etc.)</li>
                    <li>Whether a flow rate test or system inspection is included</li>
                    <li>Travel distance from lab or office</li>
                    <li>Urgency (same-day or rush requests)</li>
                </ul>

                <Typography variant="body1" className={classes.paragraph}>
                    We’re happy to provide fast, competitive quotes by phone. Whether you’re a homeowner, real estate agent, or loan officer, we’ll walk you through your options and offer a reliable estimate for the services you need.
                </Typography>

                <Box className={classes.ctaBox}>
                    <Typography variant="h5" className={classes.ctaText}>
                        Ready for a personalized quote? Call us now!
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.ctaButton}
                        href="tel:+12088189080"
                    >
                        (208) 818-9080
                    </Button>
                </Box>
            </Box>
        </section>
    );
}

export default Pricing;
